<template>
  <div>
    <h6 class="d-block mb-4">{{ $t('ACADEMICO.DESCONTOS') }}</h6>
    <b-table
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      :items="itens"
      :fields="campos"
      show-empty
      responsive
      striped
      hover
    />
  </div>
</template>

<script>
// AUX & HELPES

import helpers from '@/common/utils/helpers';

// SERVICES

//COMPONENTS

export default {
  name: 'TabelaDescontos',
  components: {},
  props: {
    itens: { type: Array, default: Array },
  },
  data() {
    return {
      campos: [
        {
          key: 'descricao',
          label: this.$t('PRECOS.DESCRICAO'),
        },
        {
          key: 'valor',
          label: this.$t('PRECOS.VALOR'),
          formatter: (value) => {
            return value ? helpers.formatarReal(value) : '-';
          },
        },
      ],
    };
  },
};
</script>
