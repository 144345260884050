import ApiService from "@/common/api/api.service";

let apiBasePath = 'InstituicaoEnsino'

const InstituicaoEnsinoService = {
    // FUNÇÕES CRUD:
    async criar(form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form);
        return data;
    },
    async obterInstituicaoPorId(instituicaoId) {
        let { data } = await ApiService.get(`${apiBasePath}/${instituicaoId}`);
        return data;
    },
    async editar(form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form);
        return data;
    },
    async excluir(instituicaoId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${instituicaoId}`);
        return data;
    },
    async obterInstituicoesTodos() {
        let { data } = await ApiService.get(`${apiBasePath}/listar-todos`);
        return data;
    },
    // FUNÇÕES CRUD - CONTATO:
    async criarContato(form) {
        let { data } = await ApiService.post(`${apiBasePath}/contato/criar`, form);
        return data;
    },
    async editarContato(form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form);
        return data;
    },
    async excluirContato(contatoId) {
        let { data } = await ApiService.delete(`${apiBasePath}/contato/remover/${contatoId}`);
        return data;
    },
    // FUNÇÕES CRUD - CONTATO:
    async criarEndereco(form) {
        let { data } = await ApiService.post(`${apiBasePath}/endereco/adicionar`, form);
        return data;
    },
    async editarEndereco(form) {
        let { data } = await ApiService.put(`${apiBasePath}/endereco/atualizar`, form);
        return data;
    },
    async excluirEndereco(enderecoId) {
        let { data } = await ApiService.delete(`${apiBasePath}/endereco/remover/${enderecoId}`);
        return data;
    },
    // FUNÇÕES FILTRO:
    async obterPorFiltro(filtro, paginacao) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-por-filtro`,
            {
                params: {
                    filtro: filtro,
                    ...paginacao
                },
            }
        );
        return data;
    },
    async obterPaginado(paginacao) {
        let { data } = await ApiService.get(`${apiBasePath}`, { params: paginacao });
        return data;
    },
}

export default InstituicaoEnsinoService;
