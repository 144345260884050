<template>
  <div>
    <modal
      id="modalAditivoContrato"
      :titulo="$t('ACADEMICO.ADITIVOS_DE_CONTRATO')"
      :exibir="exibir"
      @fechar="fecharModal"
      :campos="$refs"
      tamanho="xl"
    >
      <listagem-aditivo :itens="aditivosContrato" />
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button class="mr-2" variant="secondary" @click="fecharModal">
            {{ $t('GERAL.FECHAR') }}
          </b-button>
          <b-button class="mr-2" variant="primary" @click="irParaCriarAditivo">
            {{ $t('ACADEMICO.NOVO_ADITIVO') }}
          </b-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
// Utils & Aux:
// import rotas from '@/common/utils/rotas';
import breadcrumb from '@/common/utils/breadcrumb';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import router from '@/router/Router';

// Services
import ContratosService from '@/common/services/contratos/contratos.service';

// COMPONENTS
import Modal from '@/components/modal/Modal.vue';
import ListagemAditivo from '../../aditivo-contratos/components/tabelas/ListagemAditivo';

export default {
  name: 'ModalAditivosContrato',
  components: {
    Modal,
    ListagemAditivo,
  },
  props: {
    exibir: { type: Boolean, default: true },
    contratoId: { type: String, default: String },
    matriculaId: { type: String, default: String },
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
      { titulo: this.$t('ACADEMICO.CONTRATOS') },
      { titulo: this.$t('ACADEMICO.ADITIVOS_DE_CONTRATO') },
    ]);
  },
  watch: {
    contratoId: 'getAditivos',
  },
  data() {
    return {
      aditivosContrato: [],
    };
  },
  computed: {
    titulo() {
      return this.$t('ACADEMICO.ADITIVOS_DE_CONTRATO');
    },
  },
  methods: {
    getAditivos() {
      this.$store.dispatch(START_LOADING);
      ContratosService.buscarAditivosPorContratoId(this.contratoId)
        .then(({ data }) => {
          this.aditivosContrato = data.aditivosContratos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    fecharModal() {
      this.aditivosContrato = [];
      this.$emit('fechar');
    },
    irParaCriarAditivo() {
      router.push({
        name: 'formulario-aditivo',
        params: { matriculaId: this.matriculaId, contratoId: this.contratoId },
      });
    },
  },
};
</script>
