<template>
  <div>
    <div class="accordion" role="tablist">
      <b-card no-body v-for="item in itens" v-bind:key="item.id">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle="item.id" variant="info" class="text-left ">
            <div class="d-grid grid-cols-3">
              <span>{{ item.tipoAditivoContratoDescricao }}</span> -
              <span>{{ formatarData(item.dataGeracao) }}</span>
            </div>
          </b-button>
        </b-card-header>
        <b-collapse :id="item.id" accordion="lista-aditivos" role="tabpanel">
          <div class="bg-gray p-2">
            <h6>{{ $t('ACADEMICO.DETALHES_DO_MOTIVO') }}</h6>
            <b-card-text v-html="item.motivo" />
            <hr />

            <tabela-precos :itens="item.precos" />
            <hr />

            <listagem-contas-a-receber :itens="item.contasReceber" />
            <hr />

            <tabela-descontos :itens="item.descontos" />
          </div>
        </b-collapse>
      </b-card>
    </div>
    <div v-if="itens.length === 0" class="text-center">
      <span>{{ $t('ACADEMICO.SEM_DADOS_PARA_LISTAR') }}</span>
    </div>
  </div>
</template>

<script>
// HELPERS & AUX
import helpers from '@/common/utils/helpers';

// SERVICES

//COMPONENTS
import TabelaPrecos from './TabelaPrecos';
import TabelaDescontos from './TabelaDescontos';
import ListagemContasAReceber from './ListagemContasAReceber.vue';

export default {
  name: 'ListagemAditivos',
  components: {
    TabelaPrecos,
    TabelaDescontos,
    ListagemContasAReceber,
  },
  props: {
    itens: { type: Array, default: Array },
  },

  methods: {
    formatarData(data) {
      return helpers.formatarDataBr(data);
    },
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: #fdfdfd;
}
</style>
