<template>
  <div>
    <h6 class="d-block mb-4">{{ $t('ACADEMICO.PRECOS') }}</h6>
    <b-table
      head-variant="light"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      :items="itens"
      :fields="campos"
      responsive
      show-empty
      striped
      hover
    />
  </div>
</template>

<script>
// AUX & HELPES

import helpers from '@/common/utils/helpers';

export default {
  name: 'TabelaPrecos',
  components: {},
  props: {
    itens: { type: Array, default: Array },
  },

  data() {
    return {
      campos: [
        {
          key: 'descricao',
          label: this.$t('PRECOS.DESCRICAO'),
        },
        {
          key: 'valorBase',
          label: this.$t('PRECOS.VALOR'),
          formatter: (value) => {
            return value ? helpers.formatarReal(value) : '-';
          },
        },
        {
          key: 'formaPagamentoSelecionadaDescricao',
          label: this.$t('ACADEMICO.FORMA_PAGAMENTO'),
        },
      ],
    };
  },
};
</script>
