<template>
  <div>
    <modal
      :titulo="$t('ACADEMICO.MOTIVO_CANCELAMENTO')"
      id="modalMotivoCancelamento"
      :exibir="exibir"
      @fechar="fecharModal"
      :campos="$refs"
      :ocultarRodape="true"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <b-row>
              <b-col cols="12" class="mb-4">
                <input-select-multiple
                  ref="motivoCancelamento"
                  v-model="form.motivoCancelamento"
                  label="Motivo do cancelamento:"
                  placeholder="Digite ou procure"
                  :options="opcoes.motivoCancelamento"
                  required
                />
              </b-col>
              <b-col cols="12" class="mb-4">
                <input-select-multiple
                  ref="instituicaoEnsinoId"
                  v-model="form.instituicaoEnsinoId"
                  label="Instituição de destino:"
                  placeholder="Digite ou procure"
                  :options="opcoes.instituicaoEnsino"
                />
              </b-col>
              <b-col cols="12">
                <input-text
                  v-model="form.motivoMovimentacao"
                  ref="motivoMovimentacao"
                  label="Motivo da movimentação:"
                />
              </b-col>
            </b-row>
            <div class="text-right d-flex space-between mt-3">
              <b-button variant="secondary" @click="fecharModal">
                {{ $t('GERAL.FECHAR') }}
              </b-button>
              <b-button class="ml-2 " variant="primary" @click="salvar">
                {{ $t('GERAL.SALVAR') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/modal/Modal.vue';
// Models:
import { MotivoCancelamento } from '@/common/models';

// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

//Components:
import { InputSelectMultiple, InputText } from '@/components/inputs';

// Services:
import ContratoService from '@/common/services/contratos/contratos.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import InstituicaoEnsinoService from '@/common/services/instituicao-ensino/instituicao-ensino';

export default {
  name: 'ModalMotivoCancelamento',
  components: {
    Modal,
    InputSelectMultiple,
    InputText,
  },
  props: {
    exibir: { type: Boolean, default: true },
    contratoId: { type: String, default: String },
    motivoCancelamentoContratoId: { type: String, default: String },
  },
  data() {
    return {
      form: new MotivoCancelamento({}),
      refs: [],
      motivoCancelamento: '',
      instituicaoEnsinoId: '',
      motivoMovimentacao: '',
      opcoes: {
        motivoCancelamento: [],
        instituicaoEnsino: [],
      },
    };
  },
  watch: {
    exibir: 'obterPorId',
  },
  mounted() {
    this.getMotivoCancelamento(), this.getInstituicaoEnsino();
  },
  methods: {
    formatarForm() {
      this.form.motivoCancelamento = this.form.motivoCancelamento?.value;
      this.form.instituicaoEnsinoId = this.form.instituicaoEnsinoId?.value;
      this.form.contratoId = this.contratoId;
    },
    formatarFormSelectMultiple(data) {
      if (data !== null)
        this.form.motivoCancelamento = {
          value: data.motivoCancelamento,
          text: data.motivoCancelamentoDescricao,
        };
      this.form.instituicaoEnsinoId = {
        value: data.instituicaoEnsinoId,
        text: data.instituicaoEnsinoNome,
      };
      this.form.motivoMovimentacao = data.motivoMovimentacao;
    },
    obterPorId(exibir) {
      if (exibir !== false && this.motivoCancelamentoContratoId !== null) {
        this.$store.dispatch(START_LOADING);
        ContratoService.obterMotivoCancelamentoContratoPorIdContrato(
          this.contratoId
        )
          .then(({ data }) => {
            this.formatarFormSelectMultiple(data);
          })
          .catch((err) => {
            err.response.data.errors.forEach((element) => {
              element.tipoNotificacao === 'Erro' ||
              element.tipoNotificacao === null
                ? mensagem.erro(this.$t('GERAL.ERRO'), element.mensagem)
                : mensagem.warning(this.$t('GERAL.AVISO'), element.mensagem);
            });
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      }
    },
    salvar() {
      this.formatarForm();

      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      ContratoService.salvarMotivoCancelamento(this.form)
        .then(() => {
          const mensagemCadastroAtualizacao = this.motivoCancelamentoContratoId
            ? 'ACADEMICO.MOTIVO_CANCELAMENTO_ATUALIZAR'
            : 'ACADEMICO.MOTIVO_CANCELAMENTO_CADASTRAR';

          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t(mensagemCadastroAtualizacao)
          );
          this.fecharModal();
          this.$emit('atualizar-listagem');
        })
        .catch((err) => {
          err.response.data.errors.forEach((element) => {
            element.tipoNotificacao === 'Erro' ||
            element.tipoNotificacao === null
              ? mensagem.erro(this.$t('GERAL.ERRO'), element.mensagem)
              : mensagem.warning(this.$t('GERAL.AVISO'), element.mensagem);
          });
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getMotivoCancelamento() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('motivo-cancelamento')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.motivoCancelamento = data;
        })
        .catch((err) => {
          err.response.data.errors.forEach((element) => {
            element.tipoNotificacao === 'Erro' ||
            element.tipoNotificacao === null
              ? mensagem.erro(this.$t('GERAL.ERRO'), element.mensagem)
              : mensagem.warning(this.$t('GERAL.AVISO'), element.mensagem);
          });
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getInstituicaoEnsino() {
      this.$store.dispatch(START_LOADING);
      InstituicaoEnsinoService.obterInstituicoesTodos()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.nome,
            value: row.id,
          }));
          this.opcoes.instituicaoEnsino = data;
        })
        .catch((err) => {
          err.response.data.errors.forEach((element) => {
            element.tipoNotificacao === 'Erro' ||
            element.tipoNotificacao === null
              ? mensagem.erro(this.$t('GERAL.ERRO'), element.mensagem)
              : mensagem.warning(this.$t('GERAL.AVISO'), element.mensagem);
          });
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    fecharModal() {
      this.$emit('fechar');
      this.form = {};
    },
  },
};
</script>
