<template>
  <div>
    <topbar
      :titulo="$t('ACADEMICO.CONTRATOS')"
      :subtitulo="$t('ACADEMICO.CONTRATOS_DESCRICAO')"
      :breadcrumbs="breadcrumbs"
    />
    <b-row align-v="end" class="mb-3">
      <b-col cols="6">
        <input-select-multiple
          v-model="matriculaSelecionada"
          label="Busque por nome ou código do aluno:"
          :options="opcoes.matricula"
          placeholder="Digite ou procure"
          :loading="loadingContratos"
        />
      </b-col>
      <b-col cols="2" class="form-group mb-0" v-if="podeCadastrar">
        <b-button
          variant="info"
          @click="abrirCadastrar"
          :disabled="desabilitaBotaoCadastrar"
        >
          {{ $t('ACADEMICO.NOVO_CONTRATO') }}
        </b-button>
      </b-col>
    </b-row>
    <b-table
      head-variant="light"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      :items="items"
      :fields="campos"
      show-empty
      striped
      hover
    >
      <template #cell(acoes)="item">
        <b-dropdown variant="outline-default" v-if="mostrarAcoes">
          <b-dropdown-item @click="baixarContrato(item.item)">
            {{ $t('ACADEMICO.DOWNLOAD_CONTRATO') }}
          </b-dropdown-item>
          <b-dropdown-item
            @click="abrirModalAditivos(item.item)"
            v-if="
              item.item.statusContrato != 288 && item.item.statusContrato != 90
            "
          >
            {{ $t('ACADEMICO.ADITIVO_DE_CONTRATO') }}
          </b-dropdown-item>
          <b-dropdown-item
            @click="abrirMotivoCancelamento(item.item)"
            v-if="item.item.statusContrato == 288"
          >
            {{ $t('ACADEMICO.MOTIVO_CANCELAMENTO') }}
          </b-dropdown-item>
          <b-dropdown-item
            @click="cancelar(item.item)"
            v-if="item.item.statusContrato !== 288"
          >
            {{ $t('ACADEMICO.CANCELAR_CONTRATO') }}
          </b-dropdown-item>
          <b-dropdown-item
            @click="abrirEditarVisualizar(item.item)"
            v-if="item.item.statusContrato == 90"
          >
            {{ $t('GERAL.EDITAR') }}
          </b-dropdown-item>
          <b-dropdown-item
            @click="ativarContrato(item.item)"
            v-if="item.item.statusContrato == 90"
          >
            {{ $t('ACADEMICO.ATIVAR_CONTRATO') }}
          </b-dropdown-item>
          <b-dropdown-item
            @click="abrirEditarVisualizar(item.item, true)"
            v-if="podeVisualizar"
          >
            {{ $t('GERAL.VISUALIZAR') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <modal-motivo-cancelamento
      :exibir="mostrarModalMotivoCancelamento"
      @fechar="fecharMotivoCancelamento"
      @atualizar-listagem="listarContratosPorMatriculaId"
      :contratoId="contratoId"
      :motivoCancelamentoContratoId="motivoCancelamentoContratoId"
    />
    <modal-aditivo-contrato
      @fechar="fecharModalAditivos"
      :exibir="mostrarModalAditivos"
      :contratoId="contratoId"
      :matriculaId="matriculaSelecionada.id"
    />
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import ContratosService from '@/common/services/contratos/contratos.service';
import MatriculaService from '@/common/services/matriculas/matriculas.service';
import pdf from '@/common/utils/pdf';

// Components:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';
import { InputSelectMultiple } from '@/components/inputs';
import Permissoes from '@/components/mixins/permissoes';
import ModalMotivoCancelamento from '@/views/academico/contratos/components/ModalMotivoCancelamento';
import ModalAditivoContrato from '@/views/academico/contratos/components/ModalAditivoContrato.vue';

export default {
  mixins: [Permissoes],
  components: {
    Topbar,
    InputSelectMultiple,
    ModalMotivoCancelamento,
    ModalAditivoContrato,
  },
  data() {
    return {
      campos: [
        {
          key: 'codigoContrato',
          label: this.$t('GERAL.CODIGO'),
        },
        {
          key: 'statusDescricao',
          label: this.$t('GERAL.STATUS'),
        },
        {
          key: 'idiomaDescricao',
          label: this.$t('GERAL.IDIOMA'),
        },
        {
          key: 'anoLetivoDescricao',
          label: this.$t('GERAL.ANO_LETIVO'),
        },
        {
          key: 'tipoContratoDescricao',
          label: 'Tipo contrato',
        },
        {
          key: 'grupoTurmaDescricao',
          label: 'Grupo turma',
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
      mostrarModalAditivos: false,
      mostrarModalSelecionarMatricula: false,
      mostrarModalMotivoCancelamento: false,
      desabilitaBotaoCadastrar: true,
      items: [],
      informacoesTabela: [],
      total: 0,
      paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 10 },
      item: {},
      opcoes: {
        matricula: [],
      },
      matriculaSelecionada: '',
      matriculaSelecionadaStatus: 1,
      variantButtonMatriculaSelecionada: 'primary',
      motivoCancelamentoContratoId: '',
      contratoId: '',
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO.ACADEMICO') },
        { titulo: this.$t('ACADEMICO_MATRICULAS.CONTRATOS') },
      ],
      loadingContratos: false,
    };
  },
  computed: {
    mostrarAcoes() {
      return this.podeVisualizar || this.podeExcluir;
    },
    btnCadastrarTexto() {
      var textoBtn = '';
      switch (this.matriculaSelecionadaStatus) {
        case 1:
          textoBtn = 'Cadastrar';
          break;
        case 2:
          textoBtn = 'Matricula Inativa';
          break;
        case 3:
          textoBtn = 'Cadastro Inativo';
          break;
        default:
          break;
      }
      return textoBtn;
    },
  },
  watch: {
    matriculaSelecionada: ['listarContratosPorMatriculaId', 'botaoCadastrar'],
  },
  mounted() {
    this.listarMatriculasSemPaginacao();
    window.addEventListener('beforeunload', () => {
      this.limparLocalStorage();
    });
  },
  methods: {
    limparLocalStorage() {
      localStorage.removeItem('matriculaSelecionada');
    },
    matriculaSelecionadaStorage() {
      localStorage.setItem(
        'matriculaSelecionada',
        JSON.stringify(this.matriculaSelecionada)
      );
    },
    botaoCadastrar() {
      this.matriculaSelecionadaStorage();
      if (this.matriculaSelecionada) {
        this.desabilitaBotaoCadastrar = false;
        this.opcoes.matricula.forEach((element) => {
          if (element.id == this.matriculaSelecionada) {
            this.matriculaSelecionadaStatus = 1;
            this.variantButtonMatriculaSelecionada = 'primary';
            if (element.statusMatricula == 'Inativo') {
              this.matriculaSelecionadaStatus = 2;
              this.variantButtonMatriculaSelecionada = 'danger';
              this.desabilitaBotaoCadastrar = true;
            }
            if (element.statusPessoa == 'Inativo') {
              this.matriculaSelecionadaStatus = 3;
              this.desabilitaBotaoCadastrar = true;
              this.variantButtonMatriculaSelecionada = 'danger';
            }
            return;
          }
        });
      } else {
        this.matriculaSelecionadaStatus = 1;
        this.variantButtonMatriculaSelecionada = 'primary';
        this.desabilitaBotaoCadastrar = true;
      }
    },
    listarMatriculasSemPaginacao() {
      this.loadingContratos = true;
      MatriculaService.listarSemPaginacao()
        .then(({ data }) => {
          if (data.length) {
            data = data.map((row) => {
              return {
                ...row,
                text: `${row.codigoMatricula} - ${row.nome}`,
                value: row.id,
              };
            });

            this.opcoes.matricula = data;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingContratos = false;
        });
    },

    listarContratosPorMatriculaId() {
      if(this.matriculaSelecionada !== null && this.matriculaSelecionada.id !== undefined)
      ContratosService.buscarPorMatriculaId(
        this.matriculaSelecionada?.id,
        this.paginacao
      )
        .then((result) => {
          this.items = result.data.itens.map((item) => {
            return {
              ...item,
              idiomaDescricao: item.idioma === 0 ? ' ' : item.idiomaDescricao,
            };
          });

          this.total = result.data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    //AÇOES
    abrirModalAditivos(contrato) {
      this.contratoId = contrato.id;
      this.mostrarModalAditivos = true;
    },

    fecharModalAditivos() {
      this.contratoId = '';
      this.mostrarModalAditivos = false;
    },

    abrirCadastrar() {
      this.$router.push({
        name: 'contratos-formulario',
        query: {
          matriculaId: this.matriculaSelecionada?.id,
        },
      });
    },

    abrirEditarVisualizar(item, visualizar = false) {
      this.$router.push({
        name: 'contratos-formulario',
        query: { id: item.id, visualizar },
      });
    },

    abrirMotivoCancelamento(item) {
      this.contratoId = item.id;
      this.motivoCancelamentoContratoId = item.motivoCancelamentoContratoId;
      this.mostrarModalMotivoCancelamento = true;
    },

    fecharMotivoCancelamento() {
      this.mostrarModalMotivoCancelamento = false;
    },

    baixarContrato({ id }) {
      this.$store.dispatch(START_LOADING);
      ContratosService.baixarPDF(id)
        .then(({ data }) => {
          if (data.documento) {
            //converte os bytes em array:
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            // converte o array em blob
            let blob = new Blob([bytes], { type: 'application/pdf' });

            // faz download do PDF:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Contrato.pdf';
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    cancelar(item) {
      mensagem
        .confirmacao(null, this.$t('ACADEMICO.CONFIRMAR_CANCELAMENTO_CONTRATO'))
        .then(async (result) => {
          if (result.value) {
            this.$store.dispatch(START_LOADING);
            ContratosService.excluir(item.id)
              .then(() => {
                mensagem.sucesso(
                  this.$t('GERAL.SUCESSO'),
                  this.$t('CONTRATOS.MENSAGEM_EXCLUIDO')
                );
                mensagem
                  .confirmacao(
                    null,
                    this.$t('ACADEMICO.CONFIRMAR_MOTIVO_CANCELAMENTO')
                  )
                  .then(async (result) => {
                    if (result.value) {
                      this.abrirMotivoCancelamento(item);
                    }
                  });

                this.listarContratosPorMatriculaId();
              })
              .catch((err) => {
                mensagem.showErrors(err);
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
              });
          }
        });
    },

    ativarContrato(item) {
      mensagem
        .confirmacao(null, this.$t('ACADEMICO.CONFIRMAR_ATIVAR_CONTRATO'))
        .then(async (result) => {
          if (result.value) {
            this.$store.dispatch(START_LOADING);

            ContratosService.ativarContratoPorId(item.id)
              .then(({ data }) => {
                mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
                this.listarContratosPorMatriculaId();
              })
              .catch((err) => {
                mensagem.showErrors(err);
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
              });
          }
        });
    },
  },
};
</script>
